<template>

  <div class="appie-how-it-work-area pt-110  position-relative">
    <div class="container">
      <div class="row align-items-start">
        <div class="col-lg-4 col-md-12">
          <div class="appie-how-it-work-content text-center text-md-start">
            <h2 class="title mb-4">Преимущества</h2>
            <p class="pb-25">
              Полное искоренение краж товаров и оптимизация продаж для повышения эффективности вашего бизнеса.
            </p>
          </div>
        </div>
        <div class="col-lg-8 col-md-12">
          <div class="row justify-content-center gx-3 gy-3">
            <AdvantagesItem v-for="(item, index) in items" v-bind="item" :key="index + 'adv'"/>
          </div>
        </div>
      </div>
    </div>
    <div class="home-8-shape-1">
      <img src="@/assets/images/shape/bg-2.png" alt="">
    </div>
  </div>
</template>

<script>
import AdvantagesItem from "@/components/Home/AdvantagesItem.vue";
export default {
  components:{AdvantagesItem},
  data() {
    return {
      items: [
        {
          icon: require('@/assets/images/icon/Gear.svg'),
          title: "Оптимизируйте походы к автоматам",
          description: "Эффективное управление запасами: мы помогаем контролировать ваши запасы и планировать посещения в самое подходящее время, экономя ваше время и ресурсы."
        },
        {
          icon: require('@/assets/images/icon/Devices.svg'),
          title: "Удаленный доступ",
          description: "Включение и отключение без физического контакта: Управляйте вашей кофемашиной удаленно, не подходя к аппарату."
        },
        {
          icon: require('@/assets/images/icon/TrendUp.svg'),
          title: "Экономически выгодно",
          description: "Оптимальные тарифы: платите только за то, что используете, без скрытых платежей. Прозрачность и контроль"
        }, {
          icon: require('@/assets/images/icon/ChartBar.svg'),
          title: "Полный доступ к статистике",
          description: "Отслеживайте эффективность устройства в любое время через личный кабинет."
        }, {
          icon: require('@/assets/images/icon/ShieldCheck.svg'),
          title: "Забудьте о кражах",
          description: "Обеспечение полной защиты ваших запасов: наша система позволит вам избавиться от несанкционированных 'исчезновений' продукта. С этой технологией вы получаете полный контроль над запасами, обеспечивая надежную защиту от внутренних потерь и даря спокойствие в ведении вашего бизнеса."
        },
        {
          icon: require('@/assets/images/icon/Headset.svg'),
          title: "Постоянная поддержка",
          description: "Ваш надежный партнер: в любое время мы готовы решать возникающие проблемы за вас, обеспечивая спокойствие и уверенность в бизнесе."
        },
      ]
    }
  }
}
</script>

<style>

</style>