<template>
    <!--====== APPIE HERO PART START ======-->

    <section class="appie-hero-area appie-hero-8-area container">
            <VueSlickCarousel v-bind="settingsForItems" ref="heroCarosel" >
              <div class="">
                <div class="d-flex row justify-content-center">
                    <div class="col-lg-8">
                        <div class="appie-hero-content appie-hero-content-8">
                            <h1 class="appie-title">Бесплатное устройство для аналитики работы кофемашин</h1>
                            <p>Бесплатно установим или предоставим подробный мануал. Устройство абсолютно бесплатно, оплата
                                только за трафик.</p>
                                <div class="team-btn  mt-50">
                                    <a href="#"> Демо-доступ </a>
                                </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="thumb-center wow animated fadeInUp d-flex justify-content-center"
                            data-wow-duration="1000ms" data-wow-delay="600ms">
                            <img src="@/assets/images/hero-1.png" alt="">
                        </div>
                    </div>
                </div>
              </div>
              <div class="">
                <div class="d-flex row justify-content-center">
                  <div class="col-lg-8">
                    <div class="appie-hero-content appie-hero-content-8">
                      <h1 class="appie-title">Эффективное Управление Запасами с Телеметрией</h1>
                      <p class="custom-color">С внедрением Телеметрии у вас сократятся необъяснимые расходы на 20%, вы избавитесь от пропаж
                        кофе, значительно повышая прибыльность и контроль запасов, что сделает её ключевым элементом
                        в оптимизации вашего бизнеса.</p>
                      <div class="team-btn ml-5 mt-50">
                        <a  href="#"> Демо-доступ </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 ml-1">
                    <div class="thumb text-center wow animated fadeInUp  d-flex justify-content-center"
                         data-wow-duration="1000ms" data-wow-delay="600ms">
                      <img src="@/assets/images/hero.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
                <template #customPaging="">
                    <li></li>
                </template>
            </VueSlickCarousel>
        <div class="home-8-shape-2">
            <img src="@/assets/images/shape/shape.png" alt="">
        </div>
    </section>

    <!--====== APPIE HERO PART ENDS ======-->
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    components: { VueSlickCarousel },
    data() {
        return {
            settingsForItems: {
                autoplay: true,
                infinity: true,
                arrows: true,
                centerMode: true,
                centerPadding: '0px',
                dotsClass: 'dots-wrapper',
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 500,
            },
        }
    },
    methods: {
        showNext() {
            this.$refs.heroCarosel.next()
        },
        showPrev() {
            this.$refs.heroCarosel.prev()
        },
    }
}
</script>

<style >
.dots-wrapper {
    display: flex !important;
    justify-content: center;
    margin-top: 18px;
    gap: 8px;
}

.dots-wrapper li {
    width: 16px !important;
    height: 16px !important;
    background: url('~@/assets/images/dot.svg');
    transition: all 0.3s ease-in-out;
}

.dots-wrapper .slick-active {
    background: url('~@/assets/images/dot-active.svg');
}
.appie-hero-area {
  padding-top: 110px !important;
}
@media only screen and (min-width: 420px) {
  .appie-hero-area {
    padding-top: 150px !important;
  }
}
@media only screen and (min-width: 720px) {
  .appie-hero-area {
    padding-top: 200px !important;
  }
}
.custom-color{
  color: #262626;
}
</style>