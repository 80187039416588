<template>
    <ul class="localizations-wrapper text-center">
        <li>
            <span href="#" style="text-transform: capitalize;">{{selected}} <i v-if="nasted" class="fal fa-angle-down"></i></span>
            <ul v-if="nasted" class="sub-menu">
                <li v-for="(subItem, index) in localizations" :key="index">
                    <router-link style="text-transform: capitalize;" :to="subItem.path?subItem.path:'#'">{{subItem.name}}</router-link>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
export default {
    props:{
      selected: {
        type:String,
        required:true,
      },
        localizations: {
            type:Array,
            required:true,
        },
         nasted:{
            type:Boolean,
            default:true
        }
    },
    methods: {
    changeLanguage(language) {
      console.log(`Selected language: ${language}`);
    }
  }

}
</script>

<style>

.localizations-wrapper .sub-menu {
  left: -50% !important;
  max-width: 60px !important;
  min-width: 60px !important;
}

</style>