<template>
  <div class="wrapper d-flex flex-md-row col-md-6 col-sm-12 justify-content-start">
    <div class="wrapper-icon  d-flex d-md-block  justify-content-center">
      <img :src="icon" alt="icon">
    </div>
    <div class="text-center text-md-start">
      <span class="title">{{ title }}</span>
      <span class="description">{{ description }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "AdvantagesItem",
  props: {
    icon: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>
<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
  transition: all ease-in-out 0.2s;
}
.wrapper:hover {
  transform: translateY(-15px);
}
@media (max-width: 776px) {
  .wrapper:hover {
    transform: none;
  }
}

.wrapper-icon {
  min-width: fit-content;
}

.title {
  background: linear-gradient(136deg, #FC2E4F 0%, #4F89FF 101.25%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 25.2px */
  margin: 8px 0;
}

.description {
  color: #737373;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

</style>
