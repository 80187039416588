<template>

      <div class="appie-how-it-work-area pt-10 pb-40">
        <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="how-it-work-thumb text-center">
                  <img src="@/assets/images/coffee-machine.png" alt="coffee-machine">
                </div>
              </div>
                <div class="col-lg-6">
                    <div class="appie-how-it-work-content">
                        <h2 class="title pb-30 pt-60 text-center text-md-start">Куда устанавливать</h2>
                        <div class="row gap-3 gap-sm-4">
                            <WhereInstallItem v-for="(item, index) in items" v-bind="item" :key="index + 'install'"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WhereInstallItem from "@/components/Home/WhereInstallItem.vue";
  export default {
     components:{ WhereInstallItem},
    data() {
      return {
        items: [
          {
            index: 1,
            title: "Кофейные автоматы",
            description: "Мониторинг работы кофейных автоматов. Получайте ценные данные о работе кофейных автоматов, включая статистику использования, предпочтения клиентов и эффективность работы оборудования. Это позволит вам улучшать ассортимент напитков, оптимизировать процессы обслуживания и увеличивать удовлетворенность клиентов"
          },
          {
            index: 2,
            title: "Профессиональные кофемашины",
            description: "Оптимизация процессов в офисах и ресторанах. Анализируйте эффективность профессиональных кофемашин, установленных в офисах, кафе и ресторанах. Отслеживайте объемы потребления, это поможет вам управлять запасами сырья, обеспечивать высокое качество обслуживания и повышать лояльность клиентов"
          },
          {
            index: 3,
            title: "Автоматические кофемашины",
            description: "Контроль за качеством и производительностью. Следите за работой автоматических кофемашин, предоставляя информацию о частоте использования и качестве приготовления напитков. Это позволит вам поддерживать равномерность и высокое качество продукта, что особенно важно в случае автоматических систем приготовления кофе"
          }, {
            index: 4,
            title: "Кофемолки",
            description: "Собирайте данные о частоте использования и качестве помола кофе. Это поможет обеспечивать равномерность и качество продукта, что является ключевым фактором для получения идеального вкуса кофе. Кроме того, такие данные могут помочь в оптимизации обслуживания кофемолок"
          }
        ]
      }
    }
  }
</script>

<style>

</style>