<template>
  <section class="appie-services-2-area appie-services-8-area pt-65 pb-55" id="service">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-8">
          <div class="appie-section-title">
            <h3 class="appie-title mb-4">Как оно работает?</h3>
          </div>
        </div>
        <div class="row justify-content-center align-items-stretch align-content-stretch m-0 gx-3 gy-3">
          <howItWorksItem v-for="(item, index) in items" v-bind="item" :key="index + 'wokrs'"/>
        </div>
        <div class="mt-50">
          <div class="text-center" >
            <a class="main-btn" href="#">Попробовать бесплатно</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import howItWorksItem from './HowItWorksItem.vue'

export default {
  components: {howItWorksItem},
  data() {
    return {
      items: [
        {
          icon: require('@/assets/images/icon/like.svg'),
          title: "Бесплатное получение продукта",
          description: "Получите наше устройство абсолютно бесплатно и начните пользоваться сразу!"
        },
        {
          icon: require('@/assets/images/icon/CheckCircle.svg'),
          title: "Бесплатная установка",
          description: "Мы можем выполнить её бесплатно за вас, или вы можете использовать наш  мануал для самостоятельной установки."
        },
        {
          icon: require('@/assets/images/icon/UserCircle.svg'),
          title: "Личный кабинет со статистикой",
          description: "Вы сможете отслеживать статистику использования устройства через удобный личный кабинет на нашем сайте."
        }, {
          icon: require('@/assets/images/icon/HandCoins.svg'),
          title: "Оплата по тарифному плану",
          description: "Оплачивайте только тарифный план - без скрытых платежей и комиссий."
        }, {
          icon: require('@/assets/images/icon/ChartLineUp.svg'),
          title: "Экономичная аналитика",
          description: "Получайте аналитику работы ваших автоматов по стоимости, не превышающей цену чашки кофе."
        }
      ]
    }
  }
}
</script>

<style></style>