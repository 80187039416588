<template>
      <section class="appie-footer-area appie-footer-about-area appie-footer-8-area">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-about-widget">
                        <div class="logo">
                            <a href="#"><img src="@/assets/images/logo.png" alt=""></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget-info">
                        <h4 class="title">Будь на связи!</h4>
                        <ul>
                            <li><a href="#"><i class="fal fa-envelope"></i> papakava.coffee@gmail.com</a></li>
                            <li><a href="#"><i class="fal fa-map-marker-alt"></i> г. Житомир, Восточная 80</a></li>
                            <li><a href="#"><i class="fal fa-phone"></i> +380664085958 </a></li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>