<template>
  <div>
    <div v-if="showloading">
      <loader-appie />
    </div>
    <div v-else>
    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />
    <AuthModal/>
    <header-home-eight @toggleSidebar="toggleSidebar" :menuItems="navs" />
      <b-alert
          fade
          v-model="notification.message"
          :variant="notification.type"
          class="mt-3"
          dismissible
      >{{ notification.message }}
      </b-alert
      >
    <hero-home-eight />
    <services-home-eight />
    <Advantages/>
    <where-install />
    <sponser-home-two  title="Партнеры" description="Список наших лучших партнеров" />
    <testimonial-home-eight  />
    <footer-home-eight />
    <div class="back-to-top back-to-top-8">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
  </div>
  </div>
</template>

<script>
import SidebarHomeOne from "./SidebarHomeOne.vue";
import FooterHomeEight from "./FooterHomeEight.vue";
import HeaderHomeEight from "./HeaderHomeEight.vue";
import HeroHomeEight from "./HeroHomeEight.vue";
import TestimonialHomeEight from "./TestimonialHomeEight.vue";
import WhereInstall from "./WhereInstall.vue";
import Advantages from "@/components/Home/Advantages.vue";
import SponserHomeTwo from "@/components/Home/SponserHomeTwo.vue";
import LoaderAppie from "../LoaderAppie.vue";
import ServicesHomeEight from "./ServicesHomeEight.vue";
import AuthModal from "@/components/modals/AuthModal.vue";
import {notificationMethods} from "@/state/helpers";
export default {
  components: {
    AuthModal,
    Advantages,
    LoaderAppie,
    SidebarHomeOne,
    HeaderHomeEight,
    HeroHomeEight,
    WhereInstall,
    TestimonialHomeEight,
    SponserHomeTwo,
    FooterHomeEight,
    ServicesHomeEight
  },
  data() {
    return {
      showloading: true,
      sidebar: false,
      navs: [
        {
          name: "UA",
        },
        {
          name: "EN",
        }]
    }
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  created() {
      this.preLoading();
  },
  computed:{
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  methods: {
    ...notificationMethods,
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    preLoading() {
      setTimeout(() => {
        this.showloading = false;
      }, 2000);
    },
  },
};
</script>

<style>
</style>