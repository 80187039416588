<template >
    <div class="col-lg-4 col-md-6 mx-auto-3 " style="height: auto">
        <div class="wrapper h-100">
            <div class="appie-single-service-2 appie-single-service-about" >
                <div class="icon">
                    <img :src="icon" alt="icon" srcset="">
                </div>
                <h4 class="title fs-4 fw-medium">{{ title }}</h4>
                <p class="description lh-sm">{{ description }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "howItWorksItem",
    props: {
        icon: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    }
}
</script>
<style  scoped>
.wrapper {
    padding: 0 !important;
    border-radius: 8px;
    background: #fff;
    transition: all ease-in-out 0.2s;
}

.wrapper .icon {
    border-radius: 8px;
    width: 76px;
    height: 47px;
    background: linear-gradient(90deg, #F4E2FB 15.63%, #CDE7FE 75.52%);
    position: relative;
}

.wrapper img {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}

.wrapper:hover {
    transform: translateY(-15px);
}
@media (max-width: 776px) {
  .wrapper:hover {
    transform: none;
  }
}

.wrapper .title {
    margin: 16px 0;
    max-width: 250px;
}

.wrapper .description {
    color: #737373;
    margin-bottom: auto;
}</style> 