<script>
import {required, email} from "vuelidate/lib/validators";
import {mapState} from "vuex";

import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";
import router from "@/router";

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [{name: "description", content: "appConfig.description"}],
  },
  components: {},
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  data() {
    return {
      email: "admin@themesbrand.com",
      password: "123456",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      router.push({path: 'admin'})
    }
  },
};
</script>

<template>
  <b-modal id="auth_modal" hide-header hide-footer centered>
    <div class="d-flex align-items-center text-start pt-5 p-4 pb-0  gap-2">
      <a href="/" class="logo logo-admin">
        <img
            src="@/assets/images/user.png"
            height="50"
            alt="logo"
        />
      </a>
      <h5 class="fs-4">Авторизация</h5>
    </div>
    <div class="card-body p-4 pt-0 pb-5">
      <div class="">
        <b-alert
            v-model="isAuthError"
            variant="danger"
            class="mt-3"
            dismissible
        >{{ authError }}
        </b-alert
        >
        <b-form
            @submit.prevent="tryToLogIn"
            class="form-horizontal mt-4"
        >
          <b-form-group
              id="input-group-1"
              label="Email"
              label-for="input-1"
              class="mb-30"
              label-class="form-label"
          >
            <b-form-input
                id="input-1"
                :class="{ 'is-invalid': submitted && $v.email.$error }"
                v-model="email"
                type="email"
                placeholder="Enter email"
            ></b-form-input>
            <div
                v-if="submitted && $v.email.$error"
                class="invalid-feedback"
            >
              <span v-if="!$v.email.required">Email is required.</span>
              <span v-if="!$v.email.email"
              >Please enter valid email.</span
              >
            </div>
          </b-form-group>

          <div class="form-group row">
            <div class="col-12">
              <b-button :disabled="$v.$invalid" type="submit" variant="primary" class="w-100"
              >Авторизоваться
              </b-button
              >
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss" module></style>
