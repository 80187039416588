<template>
  <div class="appie-signup-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="appie-signup-box">
            <h3 class="fs-1 fw-bold  mb-2 mb-md-3 mb-lg-4 white">Как выжать из торговых автоматов максимум?</h3>
            <form action="#">
              <div class="input-box">
                <input type="email" placeholder="Введите свой email" />
              </div>
              <div class="input-box">
                <button type="submit">Подписаться</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>