<template>

  <div class="wrapper pb-45">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div class="appie-section-title text-center">
            <h3 class="appie-title mt-3 mt-md-4 mt-lg-5">Нам доверяют</h3>
            <p class="mt-15 mb-0 mb-md-3  mb-lg-4">Отзывы наших благодарных клиентов.</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-11">
          <div class="testimonial-about-slider-active" style="position :relative">
            <span @click.prevent="showPrev" class="prev slick-arrow" style="display: block;"><img
                src="@/assets/images/arrowCircleLeft.png" alt="right-arrow"></span>
            <VueSlickCarousel v-bind="settingsForArticle" ref="articleCarosel">
              <div v-for="(comment, index) in commentsArray" :key="'text'+index" class="testimonial-parent-item">
                <div class="testimonial-box">
                  <div class="icon">
                    <i class="fas fa-quote-left"></i>
                  </div>
                  <div class="ratings-icon">
                    <ul>
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star"></i></li>
                      <li><i class="fas fa-star"></i></li>
                    </ul>
                  </div>
                  <p class="fs-6 lh-sm">{{ comment }} </p>
                </div>
              </div>
            </VueSlickCarousel>
            <span @click.prevent="showNext" class="next slick-arrow" style="display: block;"><img
                src="@/assets/images/arrowCircleRight.png" alt="right-arrow"></span>
          </div>
        </div>
        <div class="col-lg-4 col-md-8 col-sm-10">
          <div class="testimonial-box-about-slider-small-active">
            <VueSlickCarousel v-bind="settingsForPeople" ref="peopleCarosel">
              <div class="item" v-for="(imageSrc, index) in AvatarsArray" :key="'avatar'+index">
                <div class="thumb">
                  <img :src="imageSrc" alt="user">
                </div>
                <div class="content text-center">
                  <h5 class="title">Сергей Топков</h5>
                  <span>Продукт Менеджер</span>
                </div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </div>
    <SponserHomeEight/>
  </div>

</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import SponserHomeEight from './SponserHomeEight.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: {VueSlickCarousel, SponserHomeEight},
  data() {
    return {
      settingsForArticle: {
        autoplay: false,
        arrows: false,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false
      },
      settingsForPeople: {
        centerMode: true,
        centerPadding: '0px',
        autoplay: false,
        arrows: false,
        dots: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        draggable: false
      },
      commentsArray: [
        "С того момента, как я начал использовать это устройство в своей кофейне, управление запасами и контроль над ними стали намного проще. Интересно, что после его установки я заметил значительное уменьшение необъяснимых потерь товара, что намекает на снижение случаев непреднамеренных 'пропаж'. Это не только улучшило эффективность моего бизнеса, но и способствовало повышению уровня безопасности. Благодаря точной отчетности по использованию кофе, я теперь могу лучше соответствовать предпочтениям моих клиентов, что положительно сказывается на продажах.",
        "С того момента, как я начал использовать это устройство в своей кофейне, управление запасами и контроль над ними стали намного проще. Интересно, что после его установки я заметил значительное уменьшение необъяснимых потерь товара, что намекает на снижение случаев непреднамеренных 'пропаж'. Это не только улучшило эффективность моего бизнеса, но и способствовало повышению уровня безопасности. Благодаря точной отчетности по использованию кофе, я теперь могу лучше соответствовать предпочтениям моих клиентов, что положительно сказывается на продажах.",
        "С того момента, как я начал использовать это устройство в своей кофейне, управление запасами и контроль над ними стали намного проще. Интересно, что после его установки я заметил значительное уменьшение необъяснимых потерь товара, что намекает на снижение случаев непреднамеренных 'пропаж'. Это не только улучшило эффективность моего бизнеса, но и способствовало повышению уровня безопасности. Благодаря точной отчетности по использованию кофе, я теперь могу лучше соответствовать предпочтениям моих клиентов, что положительно сказывается на продажах.",
        "С того момента, как я начал использовать это устройство в своей кофейне, управление запасами и контроль над ними стали намного проще. Интересно, что после его установки я заметил значительное уменьшение необъяснимых потерь товара, что намекает на снижение случаев непреднамеренных 'пропаж'. Это не только улучшило эффективность моего бизнеса, но и способствовало повышению уровня безопасности. Благодаря точной отчетности по использованию кофе, я теперь могу лучше соответствовать предпочтениям моих клиентов, что положительно сказывается на продажах.",
        "С того момента, как я начал использовать это устройство в своей кофейне, управление запасами и контроль над ними стали намного проще. Интересно, что после его установки я заметил значительное уменьшение необъяснимых потерь товара, что намекает на снижение случаев непреднамеренных 'пропаж'. Это не только улучшило эффективность моего бизнеса, но и способствовало повышению уровня безопасности. Благодаря точной отчетности по использованию кофе, я теперь могу лучше соответствовать предпочтениям моих клиентов, что положительно сказывается на продажах.",
        "С того момента, как я начал использовать это устройство в своей кофейне, управление запасами и контроль над ними стали намного проще. Интересно, что после его установки я заметил значительное уменьшение необъяснимых потерь товара, что намекает на снижение случаев непреднамеренных 'пропаж'. Это не только улучшило эффективность моего бизнеса, но и способствовало повышению уровня безопасности. Благодаря точной отчетности по использованию кофе, я теперь могу лучше соответствовать предпочтениям моих клиентов, что положительно сказывается на продажах.",

      ],
      AvatarsArray: [
        require("@/assets/images/avatar-3.png"),
        require("@/assets/images/avatar-2.png"),
        require("@/assets/images/avatar-4.png"),
        require("@/assets/images/avatar-6.png"),
        require("@/assets/images/avatar-5.png"),
        require("@/assets/images/avatar-1.png"),
      ]
    }
  },
  methods: {
    showNext() {
      this.$refs.articleCarosel.next()
      this.$refs.peopleCarosel.next()
    },
    showPrev() {
      this.$refs.articleCarosel.prev()
      this.$refs.peopleCarosel.prev()
    },
  }


}
</script>

<style scoped>
.testimonial-about-slider-active .testimonial-box {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.slick-arrow {
  background: none !important;
}

.slick-slide {
  padding: 0;
}

.wrapper {
  background: #EEF1F6;
}

</style>