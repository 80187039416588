<template>
    <section class="appie-testimonial-about-area">
        <div class="container">
            <div class="row top">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">{{title}} <br> {{more_title}}</h3>
                        <p>{{description}}</p>
                    </div>
                </div>
            </div>
            <div class="row bottom">
                <div class="col-lg-12">
                    <div class="appie-sponser-box d-flex justify-content-center  justify-md-content-between align-items-center center gap-3">
                        <div  v-for="(sponsorImage, index) in sponsors" class="sponser-item"  :key="index + 'sponsor'">
                          <img :src="sponsorImage" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props:{
        title:{
            type:String,
        },
        more_title:{
            type:String,
        },
        description:{
            type:String,
        }
    },
    data(){
      return{
        sponsors:[require('@/assets/images/papakava_web_logo 1.png'),
          require('@/assets/images/nespresso.png'),
          require('@/assets/images/saeco.png'),
          require('@/assets/images/delonghi.png'),
          require('@/assets/images/melitta.png')
        ],
      }
    }

}
</script>

<style>
.top{
  padding-top: 50px;
}
.bottom{
  padding-bottom: 80px;
}
.center{
  padding-top: 35px;
}
@media only screen and (min-width: 420px) {
  .top{
    padding-top: 80px;
  }
  .bottom{
    padding-bottom: 120px;
  }
  .center{
    padding-top: 50px;
  }
}
@media only screen and (min-width: 720px) {
  .top{
    padding-top: 130px;
  }
  .bottom{
    padding-bottom: 200px;
  }
  .center{
    padding-top: 80px;
  }
}
</style>