<script>
export default {
  name: "WhereInstallItem",
  props: {
    index: {
      type: Number,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
  <div class="mx-auto-3 text-center text-md-start" style="height: auto">
    <div class="d-block d-md-flex gap-3">
        <span class="index d-none d-md-block fs-1 text-lg fw-bold lh-1">
          {{ index }}
        </span>
      <div>
        <h4 class="title fs-5">{{ title }}</h4>
        <p class="description">{{ description }}</p>
      </div>
    </div>
  </div>

</template>

<style scoped>
.index {
  color: #262626;
}
</style>